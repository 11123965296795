import "./src/assets/css/style.css";
import "@fontsource/exo";
import "@fontsource/exo/500.css";
import "@fontsource/exo/500.css";
import "@fontsource/exo/400.css";
import "@fontsource/exo/800.css";
import "@fontsource/exo/900.css";
import "@fontsource/roboto";
import "@fontsource/changa-one";
import "@fontsource/hind";

import React from "react";
import mixpanel from "mixpanel-browser";

import { MixpanelContext } from "./src/metrics";
import { AppwriteContext, AppWriteProjectId, AppWriteUrl } from './src/appwriteContext';
import { ToastContainer } from 'react-toast';
import { Appwrite } from 'appwrite';

/**
 *
 * @param {import('gatsby').WrapRootElementBrowserArgs} param0
 * @returns
 */
export const wrapRootElement = ({ element }) => {
    const prod = "1b9eb8e9fbf1961efbb6c91760ef294c";
    const dev = "ed2b85f6ecdbd6bb20d502b467e88c61";
    const mixpanel_token = process.env.NODE_ENV === "production" ? prod : dev;

    mixpanel.init(mixpanel_token, {
        batch_size: 1,
        debug: process.env.NODE_ENV !== "production",
        ignore_dnt: true,
    });
    // if (process.env.NODE_ENV !== "production") {
    //     mixpanel.disable();
    // }

    const appwrite = new Appwrite();
    appwrite.setEndpoint(AppWriteUrl);
    appwrite.setProject(AppWriteProjectId);

    return (
        <MixpanelContext.Provider value={mixpanel}>
            <AppwriteContext.Provider value={appwrite}>

            {" "}
            {element}{" "}
            <ToastContainer position='top-right' delay={3000} />
            </AppwriteContext.Provider>
        </MixpanelContext.Provider>
    );
};

/**
 *
 * @param {string} path
 * @returns {string} Path wihout trailing slash
 */
function removeTrailingSlash(path) {
    return path.endsWith('/') ? path.slice(0, -1) : path;
}

/**
 * @param {import('gatsby').RouteUpdateArgs} param0
 */
export const onRouteUpdate = ({ location, prevLocation }) => {
    const path = removeTrailingSlash(location.pathname);
    const prevPath = prevLocation ? removeTrailingSlash(prevLocation.pathname) : 'None';
    mixpanel.track("Navigate page", {
        location: path,
        prevLocation: prevPath,
    });
    mixpanel.people.increment('NavigateCount');
    mixpanel.people.set_once({
        'First Visit Date': new Date()
    });
    mixpanel.people.append('Pages Visited', path);
};


